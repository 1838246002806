@import url('https://fonts.googleapis.com/css2?family=Inspiration&display=swap');

.hearted-background:before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    background-image: url("./../public/img/jumbotron.jpg");
    background-size: cover;
    background-position: center center;
}
.hearted-background{
    background-color: rgba(247, 7, 7, .2) !important;
}

.main-jumbotron{
    position: relative;
    font-weight: bold;
    
}
.main-jumbotron .card{
    background-color: rgba(0,0,0, 0.1);
    
}
.main-jumbotron .col-timer{
    padding-right: 5px;
    padding-left: 5px;
}
.main-jumbotron h1{
    font-family: 'Inspiration', cursive;
    font-size: 120px
}
.main-jumbotron .container-fluid{
    position: relative;
}
.jumbotron{
    border-bottom: 10px solid rgb(247, 7, 7);
}
.carousel-item{
    height: 550px;
    padding: 5px 0;
}

.carousel-item img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.confetti-container{
    position: absolute;
    z-index: 2
}
.confetti-container-lt{
    left:0;
    top:0;
}
.confetti-container-rb{
    right:0;
    bottom:0;
}
.confetti-container-rt{
    right: 0;
    top:0;
}
.confetti-container-lb{
    left:0;
    bottom: 0;
}
.confetti-container-cc{
    left:50%;
    top: 45%;
}